<template>
    <div class="user-profile">
        <transition name="bounce"
         mode="out-in">
            <div class="full-page"
             v-if="this.loader">
                <LoaderView />
            </div>
            <div v-else
             class="user-profile">
                <img v-if="user.sex > 0"
                 class="profile-pic"
                 :src="man"
                 alt="profile picture">
                <img v-else
                 class="profile-pic"
                 :src="woman"
                 alt="profile picture">
                <h3>{{ user.name }} {{ user.surname }}</h3>
                <transition name="bounce">
                    <InputNotify v-if="showNotify.show"
                     :icon="showNotify.icon"
                     :text="showNotify.text" />
                </transition>
                <div class="user-profile-edit-grid">
                    <div v-if="user.access_pin"
                     class="disabled-field user-profile-item">
                        <p>{{ $t('dashboard.pages.profile.doorCode') }}:</p>
                        <p>#{{ user.access_pin }}</p>
                    </div>
                    <input v-model="user.name"
                     class="user-profile-item"
                     type="text">
                    <input v-model="user.surname"
                     class="user-profile-item"
                     type="text">
                    <div v-if="user.idcode"
                     class="disabled-field user-profile-item">
                        {{ user.idcode }}
                    </div>
                    <div class="disabled-field user-profile-item">
                        {{ user.email1 }}
                    </div>
                    <input v-model="user.address"
                     class="user-profile-item"
                     placeholder="Address"
                     type="text">
                    <SelectTag :options="cities"
                     class="user-profile-item"
                     mode="json"
                     :label="this.cities.find(city => city.id === this.user.city_id).name"
                     @select="(city) => user.city_id = city"
                     style="max-width: 550px;" />
                    <input v-model="user.postcode"
                     class="user-profile-item"
                     placeholder="Zip code"
                     type="text">
                    <input v-model="user.phone1"
                     class="user-profile-item"
                     placeholder="+372 555 5555"
                     type="phone">
                </div>
                <Button @click="updateUser"
                 :widthRestrict="true"
                 :text="$t('dashboard.pages.profile.update')" />
            </div>
        </transition>
    </div>
</template>

<script>
import Button from '@/components/buttons/MainButton.vue';
import SelectTag from '../selectors/SelectTag.vue';
import LoaderView from '../buttons/LoaderView.vue';
import InputNotify from '../InputNotify.vue';
import check from '../../assets/svg/check.svg';
import xmark from '../../assets/svg/xmark.svg';
import man from '../../assets/img/dashboard/man.svg';
import woman from '../../assets/img/dashboard/woman.svg';

export default {
    name: 'UserProfile',
    components: {
        Button,
        LoaderView,
        InputNotify,
        SelectTag
    },
    data() {
        return {
            man,
            woman,
            loader: true,
            check,
            xmark,
            showNotify: {
                show: false,
                text: '',
                icon: ''
            },
            user: null,
            footnote: false,
            cities: [
                { id: 4370, name: "Tallinn" },
                { id: 4683, name: "Tartu" },
                { id: 4684, name: "Narva" },
                { id: 4685, name: "Pärnu" },
                { id: 4690, name: "Rae" },
                { id: 4691, name: "Jõelähtme" },
                { id: 4692, name: "Saku" },
                { id: 4698, name: "Paide" },
                { id: 4713, name: "Järveküla" },
                { id: 4779, name: "Viimsi" },
                { id: 4843, name: "Laiaküla" },
                { id: 4844, name: "Maardu" },
                { id: 4845, name: "Püha küla" },
                { id: 4847, name: "Loo" },
                { id: 4924, name: "Peetri" },
                { id: 5065, name: "Tabasalu" },
                { id: 5097, name: "Rapla" },
                { id: 5110, name: "Kaberla" },
                { id: 5128, name: "Laagri" },
                { id: 5172, name: "Jüri" },
                { id: 5365, name: "Tartu" },
                { id: 5366, name: "Raesiku vald" },
                { id: 5776, name: "Kiisa" },
                { id: 6339, name: "Paldiski" },
            ],
        }
    },
    methods: {
        fetchUser() {
            this.loader = true;
            fetch('https://app.35fit.com/api/users', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.loader = false
                        this.user = data.user
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                })
        },
        updateUser() {

            fetch('https://app.35fit.com/api/users/edit', {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.user)

            })
                .then(res => res.json())
                .then(data => {
                    if (data.success) {
                        this.user = data.data
                        this.showNotify = {
                            show: true,
                            text: 'User data has been updated',
                            icon: this.check
                        }
                        setTimeout(() => {
                            this.showNotify.show = false
                        }, 2000)
                        return
                    }
                    this.showNotify = {
                        show: true,
                        text: data.error,
                        icon: this.xmark
                    }
                })
                .catch(err => {
                    console.log(err),
                        this.showNotify = {
                            show: true,
                            text: err.message,
                            icon: this.xmark
                        }
                })
        },

    },
    mounted() {
        this.fetchUser();

    }
}
</script>

<style>
.footnote {
    font-size: 12px;
    color: #999;
    text-align: left;
}


.full-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.user-profile {
    width: 100%;
    padding: 10px 0 20px 0;
    height: fit-content;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
}

.user-profile-edit-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.user-profile-item {
    flex: 1 1 280px;
    max-width: unset !important;
}

.user-profile h3 {
    text-align: center;
}

.profile-pic {
    width: 100px;
    height: 100px;
}
</style>
