<template>
    <div class="faq-wrapper">
        <div v-for="section in faq"
         :key="section.name"
         class="faq-group">
            <div>
                <h3 class="faq-sticky">{{ section.name }}</h3>
            </div>
            <div class="faq-container">
                <div v-for="item in section.items"
                 :key="item.id">
                    <FaqItem :title="item.title"
                     :text="item.text" />
                </div>
            </div>
        </div>


        <section id="about">
            <div class="faq-group">
                <div>
                    <h3 class="faq-sticky">{{ $t('footer.about') }}</h3>
                </div>

                <div class="faq-contacts-flex">
                    <div>
                        <h4>{{ $t('faq.about.legal') }}</h4>
                        <ul class="faq-constacts-list">
                            <li>35FIT OÜ</li>
                            <li>Registrikood: 14520329</li>
                            <li>VAT: EE102088467</li>
                            <li>IBAN: EE612200221069641946</li>
                        </ul>
                    </div>

                    <div>
                        <h4>{{ $t('faq.about.address') }}</h4>
                        <ul class="faq-constacts-list">
                            <li>Gonsiori 33, Kesklinn, 10147</li>
                            <li>Tallinn, Harjumaa, Eesti</li>
                            <li>+372 5843 5415</li>
                            <li>info@35fit.com</li>
                        </ul>
                    </div>

                    <div>
                        <h4>{{ $t('faq.about.openHours') }}</h4>
                        <ul class="faq-constacts-list">
                            <li>{{ $t('faq.about.monFri') }}: 07:00 - 21:00</li>
                            <li>{{ $t('faq.about.satSun') }}: 09:00 - 19:00</li>
                            <li>{{ $t('faq.about.stateHolidays') }}: 09:00 - 19:00</li>
                            <li>{{ $t('faq.about.office') }}: 10:00 - 18:00 (tööpäevad)</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
import FaqItem from '@/components/FaqItem.vue'

export default {
    name: 'FaqPage',
    components: {
        FaqItem,
    },
    data() {
        return {
            faq: [
                {
                    name: this.$t('faq.contract.name'),
                    items: [
                        {
                            id: 1,
                            title: this.$t('faq.contract.first.title'),
                            text: this.$t('faq.contract.first.text')
                        },
                        {
                            id: 2,
                            title: this.$t('faq.contract.second.title'),
                            text: this.$t('faq.contract.second.text')
                        },
                        {
                            id: 3,
                            title: this.$t('faq.contract.third.title'),
                            text: this.$t('faq.contract.third.text')
                        },
                        {
                            id: 4,
                            title: this.$t('faq.contract.fourth.title'),
                            text: this.$t('faq.contract.fourth.text')
                        },
                        {
                            id: 5,
                            title: this.$t('faq.contract.fifth.title'),
                            text: this.$t('faq.contract.fifth.text')
                        },
                        {
                            id: 6,
                            title: this.$t('faq.contract.sixth.title'),
                            text: this.$t('faq.contract.sixth.text')
                        },
                        {
                            id: 7,
                            title: this.$t('faq.contract.seventh.title'),
                            text: this.$t('faq.contract.seventh.text')
                        },
                        {
                            id: 8,
                            title: this.$t('faq.contract.eight.title'),
                            text: this.$t('faq.contract.eight.text')
                        },
                        {
                            id: 9,
                            title: this.$t('faq.contract.ninth.title'),
                            text: this.$t('faq.contract.ninth.text')
                        },
                        {
                            id: 10,
                            title: this.$t('faq.contract.tenth.title'),
                            text: this.$t('faq.contract.tenth.text')
                        },

                    ]
                },
                {
                    name: this.$t('faq.training.name'),
                    items: [
                        {
                            id: 1,
                            title: this.$t('faq.training.first.title'),
                            text: this.$t('faq.training.first.text')
                        },
                        {
                            id: 2,
                            title: this.$t('faq.training.second.title'),
                            text: this.$t('faq.training.second.text')
                        },
                        {
                            id: 3,
                            title: this.$t('faq.training.third.title'),
                            text: this.$t('faq.training.third.text')
                        },
                        {
                            id: 4,
                            title: this.$t('faq.training.fourth.title'),
                            text: this.$t('faq.training.fourth.text')
                        },
                        {
                            id: 5,
                            title: this.$t('faq.training.fifth.title'),
                            text: this.$t('faq.training.fifth.text')
                        },
                        {
                            id: 6,
                            title: this.$t('faq.training.sixth.title'),
                            text: this.$t('faq.training.sixth.text')
                        },
                        {
                            id: 7,
                            title: this.$t('faq.training.seventh.title'),
                            text: this.$t('faq.training.seventh.text')
                        },
                    ]
                },
                {
                    name: this.$t('faq.other.name'),
                    items: [
                        {
                            id: 1,
                            title: this.$t('faq.other.first.title'),
                            text: this.$t('faq.other.first.text')
                        },
                        {
                            id: 2,
                            title: this.$t('faq.other.second.title'),
                            text: this.$t('faq.other.second.text')
                        },
                        {
                            id: 3,
                            title: this.$t('faq.other.third.title'),
                            text: this.$t('faq.other.third.text')
                        },
                    ]
                },

            ]
        }
    },


}
</script>
<style>
.faq-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    margin-bottom: 70px;
}

.faq-contacts-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
}

.faq-constacts-list {
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;

    font-size: 14px;
    gap: 5px;
}

.faq-sticky {
    position: sticky;
    top: 30px;
}

.faq-contacts-flex,
.faq-container {
    grid-area: 1 / 2 / 2 / 5;
}

.faq-group h3 {
    grid-area: 1 / 1 / 2 / 2;
}


@media screen and (max-width: 575px) {
    .faq-group {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 15px;
    }

    .faq-sticky {
        position: inherit;
    }

    .faq-contacts-flex,
    .faq-container {
        grid-area: 2 / 1 / 2 / 1;
    }

    .faq-contacts-flex {
        flex-direction: column;
        gap: 20px;
    }

    .faq-group h3 {
        grid-area: 1 / 1 / 1 / 1;
    }

}
</style>
